<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">GameChanger</h2>
        </b-link>

        <b-card-title class="mb-1"> Welcome to GameChanger! 👋 </b-card-title>
        <b-card-text class="mb-2">
          Please sign-up to your account and start the adventure
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-if="!waitingForCode"
          v-slot="{ invalid }"
        >
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="Phone" label="Phone Number">
              <validation-provider #default="{ errors }" name="Phone">
                <!-- <b-form-input
                  id="phone"
                  v-model="userPhone"
                  name="login-phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="+2(xxx.xxx.xxxx)"
                  autofocus
                /> -->
                <VuePhoneNumberInput
                  v-model="userPhone"
                  show-code-on-list
                  auto-detect
                  fetch-country
                  clearable
                  :color="mycolor"
                  :valid-color="correctColor"
                  required
                  @update="resultsUserPhone = $event"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Pin Code"
                vid="password"
                rules="digits:6|required"
              >
                <b-input-group prepend="Pin Code" class="mt-1">
                  <b-form-input
                    v-model="password"
                    name="login-password"
                    maxlength="6"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :variant="
                        passwordFieldType === 'password'
                          ? 'outline-secondary'
                          : 'outline-primary'
                      "
                      @click="togglePasswordVisibility"
                    >
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Password Confirmation"
                rules="required|confirmed:password"
              >
                <b-input-group prepend="Confirm Pin" class="mt-1">
                  <b-form-input
                    v-model="passwordConfirmation"
                    name="login-password"
                    maxlength="6"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                  ></b-form-input>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="!isValid || invalid"
              @click="sendVerificationCode"
            >
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <validation-observer
          ref="validation-form"
          v-if="waitingForCode"
          v-slot="{ invalid }"
        >
          <b-card-text>
            We have sent a verification code to your phone number. Please enter
            it below to complete your registration process.
          </b-card-text>
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group label-for="OTP" label="OTP Code">
              <validation-provider
                #default="{ errors }"
                name="OTP"
                :rules="{
                  required: true,
                  regex: /^[0-9]{5}$/,
                  length: 5,
                }"
              >
                <b-form-input
                  id="otp"
                  v-model="otp"
                  name="login-phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="12345"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="signup"
            >
              Verify
            </b-button>
          </b-form>
        </validation-observer>
        <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>
        <b-card-text class="text-center mt-2">
          <span>Have an account? </span>
          <b-link :to="{ name: 'login' }">
            <span>sign-in</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import Backendless from "backendless";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import { regex } from "vee-validate/dist/rules";
import VuePhoneNumberInput from "vue-phone-number-input";
import Twilio from "@/libs/Twilio";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userPhone: "",
      resultsUserPhone: null,
      mycolor: "#F00000",
      correctColor: "#02B30A",
      password: "",
      status: "",
      transactionId: "",
      otp: "",
      passwordConfirmation: "",
      waitingForCode: false,
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    isValid() {
      return this.resultsUserPhone != null
        ? this.resultsUserPhone.isValid
        : false;
    },
  },

  methods: {
    sendVerificationCode() {
      let that = this;
      Backendless.Users.register({
        phoneNumber: this.resultsUserPhone.formattedNumber,
        password: this.password,
        smsCodeAuth: true,
      })
        .then(function (registeredUser) {
          console.log(registeredUser);
          that.transactionId = registeredUser.transactionId;
          that.waitingForCode = true;
        })
        .catch(function (error) {
          console.log("error message - " + error.message);
        });
    },
    signup() {
      let that = this;
      Backendless.Users.register({
        password: this.password,
        phoneNumber: this.resultsUserPhone.formattedNumber,
        transactionId: this.transactionId,
        transactionCode: this.otp,
      }).then(
        function (registeredUser) {
          console.log("user has been registered");
          that.$router.push({
            name: "home",
          });
        },
        function (error) {
          console.log("error message - " + error.message);
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
