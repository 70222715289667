var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("GameChanger")])]),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Welcome to GameChanger! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please sign-up to your account and start the adventure ")]),(!_vm.waitingForCode)?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"Phone","label":"Phone Number"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VuePhoneNumberInput',{attrs:{"show-code-on-list":"","auto-detect":"","fetch-country":"","clearable":"","color":_vm.mycolor,"valid-color":_vm.correctColor,"required":""},on:{"update":function($event){_vm.resultsUserPhone = $event}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Pin Code","vid":"password","rules":"digits:6|required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input-group',{staticClass:"mt-1",attrs:{"prepend":"Pin Code"}},[_c('b-form-input',{attrs:{"name":"login-password","maxlength":"6","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":_vm.passwordFieldType === 'password'
                        ? 'outline-secondary'
                        : 'outline-primary'},on:{"click":_vm.togglePasswordVisibility}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password Confirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input-group',{staticClass:"mt-1",attrs:{"prepend":"Confirm Pin"}},[_c('b-form-input',{attrs:{"name":"login-password","maxlength":"6","state":errors.length > 0 ? false : null,"type":"password"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":!_vm.isValid || invalid},on:{"click":_vm.sendVerificationCode}},[_vm._v(" Sign up ")])],1)]}}],null,false,3526265468)}):_vm._e(),(_vm.waitingForCode)?_c('validation-observer',{ref:"validation-form",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('b-card-text',[_vm._v(" We have sent a verification code to your phone number. Please enter it below to complete your registration process. ")]),_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"OTP","label":"OTP Code"}},[_c('validation-provider',{attrs:{"name":"OTP","rules":{
                required: true,
                regex: /^[0-9]{5}$/,
                length: 5,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-input',{attrs:{"id":"otp","name":"login-phone","state":errors.length > 0 ? false : null,"placeholder":"12345","autofocus":""},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid},on:{"click":_vm.signup}},[_vm._v(" Verify ")])],1)]}}],null,false,1640127814)}):_vm._e(),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v("or")])]),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Have an account? ")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v("sign-in")])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }